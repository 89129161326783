import Image from "next/image";
import logo from "/public/img/logo.svg";
import googlePlayer from "/public/img/googleplay.svg";
import appStore from "/public/img/appstore.svg";
import instagram from "/public/img/instagram.svg";
import youtube from "/public/img/Youtube.svg";
import venture_enterprise_horizontal from "/public/img/venture_enterprise_horizontal.png";
import Link from "next/link";
import { t } from "i18next";

export default function Footer() {
	return (
		<>
			<div className="section py-5 over-hide background-blue-4">
				<div className="container-fluid px-xl-5 pt-4 pb-2">
					<div className="row">
						<div className="col-lg-5 col-xl-4 logo-footer text-center text-lg-left">
							<Link href="/">
								{/* <Image src={logo} alt="" /> */}
							</Link>
							{/* <p className="mb-1 size-13">주식회사 에듀비아</p> */}

							<p className="mb-1 size-20 mt-4 pt-2">(주)에듀비아</p>
							<p className="mb-1 size-13">대표 : 김선국</p>
							
							<p className="mb-1 size-13">{t("footer.paragraph1")}</p>
							<p className="mb-1 size-13">{t("footer.paragraph2")}</p>
							<p className="mb-1 size-13">{t("footer.paragraph3")}</p>
							<p className="mb-1 size-13">고객센터 : 070-4155-8282</p>
							<p className="mb-0 size-13">
								{t("footer.customerInquiry")}{" "}
								<Link href={`mailto:${t("footer.customerInquiryEmail")}`} className="link-normal">
									{t("footer.customerInquiryEmail")}
								</Link>
							</p>
						</div>
						<div className="col-lg-auto d-none d-lg-inline">
							<p className="mb-2 size-14">
								<Link href="/companyinformation" className="link-normal ">
									{t("footer.about")}
								</Link>
							</p>
							{/*<p className="mb-2 size-14"><Link href="/partners" className="link-normal ">{t('footer.partners')}</Link></p>*/}
							<p className="mb-2 size-14">
								<Link href="/services" className="link-normal ">
									{t("footer.services")}
								</Link>
							</p>
							<p className="mb-2 size-14">
								<Link href="/testanalysis" className="link-normal ">
									{t("footer.analysis")}
								</Link>
							</p>
							<p className="mb-2 size-14">
								<Link href="/news" className="link-normal ">
									{t("footer.news")}
								</Link>
							</p>
							<p className="mb-0 size-14">
								<Link href="/contact" className="link-normal ">
									{t("footer.contact")}
								</Link>
							</p>
						</div>
						<div className="col-lg text-center text-lg-right mt-4 mt-lg-0">
							<Link href={process.env.NEXT_PUBLIC_YOUTUBE_LINK} target="_blank" className="btn btn-white btn-radius parallax-elements js-tilt mx-1 mx-lg-0 mr-lg-4 mb-1">
								<Image src={youtube} alt="" />
							</Link>
							<Link href={process.env.NEXT_PUBLIC_INSTAGRAM_LINK} target="_blank" className="btn btn-white btn-radius parallax-elements js-tilt mx-1 mx-lg-0 mb-1">
								<Image src={instagram} alt="" />
							</Link>
							<div className="section text-center text-lg-right mt-4 mt-lg-5 pt-0 pt-lg-5">
								<Image src={venture_enterprise_horizontal} width="180" alt="" />
								<p className="mb-2 mt-2 size-14">
									<Link href="/policy/terms-of-service" className="link-normal  mr-2">
										{t("footer.terms")}
									</Link>
									<Link href="/policy/privacy" className="link-normal  ml-2">
										{t("footer.privacyPolicy")}
									</Link>
									<Link href="/policy/marketing-information" className="link-normal  ml-2">
										{t("footer.marketingAgreement")}
									</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="section over-hide py-4">
				<div className="container-fluid px-xl-5">
					<div className="row justify-content-center">
						<div className="col-auto pr-2 d-none">
							<Link href={process.env.NEXT_PUBLIC_YOUTUBE_LINK} target="_blank" className="link-normal weight-600">
								<i className="uil uil-youtube size-20"></i>
							</Link>
						</div>
						<div className="col-auto pl-2 d-none">
							<Link href={process.env.NEXT_PUBLIC_INSTAGRAM_LINK} target="_blank" className="link-normal weight-600">
								<i className="uil uil-instagram size-20"></i>
							</Link>
						</div>
						<div className="col-lg text-center text-lg-right mt-3 mt-lg-0">
							<p className="mb-2 size-13">ⓒ 2023 EDUVIA CO. LTD. All rights reserved.</p>
						</div>
					</div>
				</div>
			</div>

			<div className="progress-wrap">
				<svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
					<path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
				</svg>
			</div>
		</>
	);
}
